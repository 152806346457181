export default {
	  redirectUri: 'https://intercab.alianca.com.br/',
    apiUri: 'https://api.alianca.com.br/intercab',
	  apiMaerskUri: 'https://api.maersk.com/intercab',
    fileReference: 'environment.prod.ts',
	  ambient: 'prod' as const,
    version: '2.0.0',
    consumerkey: 'oAYLB1lpDPv4LLKQ0h1Vw4hxwK5XBXL8',
    instrumentationKey: '0c122a13-be1a-4afe-b9b2-34d1648ebd86',
    azfUri: 'https://api.alianca.com.br/intercab/function/uploadfilestoblob',
		commercialUri: 'https://api.alianca.com.br/intercab' + '/commercial/v1/commercial/',
		postponeUri: 'https://api.alianca.com.br/intercab/postponed-validity/v1/postponed-validity/agreement/',
		ldClientSideId: '66a8a5624843c8106172d0b9',
		ldContextKey: 'anl-intercab-IntercabWebApp',
		ldContextName: 'anl-intercab-IntercabWebApp'
}