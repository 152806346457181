export default {
	redirectUri: 'https://intercab-qa.alianca.com.br/',
	apiUri: 'https://api-cdt.alianca.com.br/intercab',
	apiMaerskUri: 'https://api-cdt.maersk.com/intercab',
	fileReference: 'none.ts',
	ambient: 'qa' as const,
	version: '2.0.0',
	consumerkey: 'dfGVPhkDuvmw8Z18v7vUCblfCE4yuFPx',
	instrumentationKey: '30287add-fbe6-4f4e-a51a-c04a3b7f5f94',
	azfUri: 'https://faazbsrg2qhsud001upf.azurewebsites.net',
	commercialUri: 'https://api-cdt.alianca.com.br/intercab' + '/commercial/v1/commercial/',
	postponeUri: 'https://api-cdt.alianca.com.br/intercab/postponed-validity/v1/postponed-validity/agreement/',
	ldClientSideId: '67dab70bbff3d309b0a06847',
	ldContextKey: 'anl-intercab-IntercabWebApp',
	ldContextName: 'anl-intercab-IntercabWebApp'
};